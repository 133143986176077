<template lang="">
    <div class="formList">
        <div class="formList__content">
            <h3 class="formList__title">{{ title }}</h3>
            <a-button type="primary" :style="{ color: '#fff' }" @click="handleClickCreate">
                {{ $t('form_page.create_new') }}
            </a-button>
            <div class="formList__table">
                <a-table :columns="columns" :data-source="data" :pagination="false" :loading="isLoadingTable">
                    <span slot="action" slot-scope="text, record">
                        <a-button @click="handleClickOpen(text)"> {{ $t('table_button.open_form') }} </a-button>
                    </span>
                    <span slot="created_at" slot-scope="text, record">{{ getFormatDate(+text) }}</span>
                    <span slot="updated_at" slot-scope="text, record">{{ getFormatDate(+text) }}</span>
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
import { FormQuestion } from '../../api';
import { mixinToasts, mixinLoadings, mixinFormats } from '../../mixins';

export default {
    mixins: [mixinToasts, mixinLoadings, mixinFormats],
    data() {
        return {
            data: [],
            columns: [
                {
                    dataIndex: 'id',
                    title: this.$t('table_header.actions'),
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                },
                {
                    dataIndex: 'created_at_unix_timestamp',
                    title: this.$t('form.created_at'),
                    key: 'created_at',
                    scopedSlots: { customRender: 'created_at' },
                },
                {
                    dataIndex: 'updated_at_unix_timestamp',
                    title: this.$t('form.updated_at'),
                    key: 'updated_at',
                    scopedSlots: { customRender: 'updated_at' },
                },
            ],
            title: '',
            isLoadingTable: true,
        };
    },
    async mounted() {
        await this.getTitleForm();
        await this.getListForm();
    },
    methods: {
        handleClickCreate() {
            this.$router.push({
                path: `/form-write-page/${this.$route.params.id}`,
                query: { course_id: this.$route.query.course_id },
            });
        },

        handleClickOpen(id) {
            this.$router.push({
                path: `/form-view-page/${this.$route.params.id}`,
                query: { course_id: this.$route.query.course_id, record_id: id },
            });
        },

        async getTitleForm() {
            try {
                const res = await FormQuestion.getForm(this.$route.params.id, {
                    fields: ['title'],
                });
                this.title = res?.results?.object?.title;
            } catch (error) {
                this.showError(error);
            }
        },

        async getListForm() {
            try {
                const res = await FormQuestion.getFormAttend({
                    fields: ['$all'],
                    where: { form_id: this.$route.params.id },
                    course_id: this.$route.query.course_id,
                });
                this.data = res?.results?.objects?.rows;
            } catch (error) {
                this.showError(error);
            }
            this.isLoadingTable = false;
        },
    },
};
</script>

<style lang="scss">
.formList {
    width: 100%;
    max-width: 1170px;
    padding: 0 15px;
    margin: 80px auto;
    & .formList__content {
        padding: 30px;
        background-color: #fff;
        border-radius: 16px;
    }
}

.formList__title {
    margin-bottom: 20px;
    font-size: 24px;
}

.formList__table {
    margin-top: 20px;
    & .ant-table-thead > tr > th {
        background-color: #fafafa;
        font-size: 14px;
    }
}
</style>
